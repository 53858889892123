import Keycloak from "keycloak-js";

const keycloak = new Keycloak({
  //url: "http://localhost:8080",
  url: 'https://www.auth.leprestigehall.net/auth',
  realm: "LePrestigeHall",
  clientId: "le-prestige-hall-ui",
});

export default keycloak;
